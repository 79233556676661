import IndexTable from './indexTable';
import FoldersTree from './foldersTree';
import StateRecovery from '../../common/components/stateRecovery';
import {convertSelectionsToIds} from '../../utils/tableUtils';
import utils from '../../common/components/utils.js';
import LocalStorageKeysService from '../../common/service/localStorageKeysService'

export default class VirtualCollection extends IndexTable {

	initialize(options) {
		this.showAllChildren=$(options.el).attr('data-show-all-children')
		this.typeId = options.typeId
		this.isField = true;
		this.initPropmise = new Promise((resolve, reject) => {
			options.resolveFunc = () => resolve()
			super.initialize(options)
		}).then(() => {
			if (options.folders && options.folders.length && this.hasFolderTree) {
				this.tree = new FoldersTree({
					el: options.folders,
					captionEl: options.caption,
					addInstance: () => this.addInstance(),
					table: this.foldersTreeStruct,
					treeViewKind: options.treeViewKind,
					typeId: options.typeId,
					fieldId: options.fieldId,
					showInstance: (typeId, rowId) => this.showInstance(typeId, rowId),
					addNewButton: this._get('add'),
					addNewInContextMenu: true,
					dndEnabled: true,
					statefull: true,
					showRoot: true,
					isTreeSelectable: this.isTreeSelectable,
					onlyFolderTree: this.onlyFolderTree,
					updateTableSelection: () => { this.updateTableSelection() },
					showAllChildren: this.showAllChildren,
					loadAllInstances: (reloadTable) => { this.loadAllInstances(reloadTable) }
				});
				this.setTree(this.tree);
				this._get('toggleFolders').first().click(() => this.toggleFolders());
				this.foldersClosed = StateRecovery.get(LocalStorageKeysService.buildKeyForFoldersTree(options.fieldId));
				if (!this.foldersClosed) {
					this.toggleFoldersNoAnimation();
				}
				this.$el.closest('.row').addClass('flex-collection')
				this.$el.closest('.row').find('.folders-tree').parent().resize(() => { this.updateWidth() })
				this.tree.render();
				this.tree.$el.css('max-height', 'calc(100vh - 200px)')
				this.tree.$el.perfectScrollbar();
				this.tree.$el.parent().resizable({
					minWidth: 50,
					handles: 'e'
				});
			}
		})
	}

	toggleFolders (invisible) {
		this.tree.$el.parent().toggleClass('col-0 col-md-2');
		if (!invisible) {
			this.foldersClosed = !this.foldersClosed;
			StateRecovery.put(LocalStorageKeysService.buildKeyForFoldersTree(this.tree.fieldId), this.foldersClosed);
			this.updateWidth()
		}
	}

	toggleFoldersNoAnimation () {
		this.tree.$el.parent().toggleClass('slide-content no-slide-content');
		this.tree.$el.parent().next().toggleClass('slide-content no-slide-content');
		this.toggleFolders(true);
		this.tree.$el.parent().toggleClass('slide-content no-slide-content');
		this.tree.$el.parent().next().toggleClass('slide-content no-slide-content');
	}

}

/* @flow */
import BlockType from './blockType.js';

export default class BlockTrigger {
	static DOCUMENT_POST = 'DOCUMENT_POST';
	static SAVE = 'SAVE';
	static FIELD_CHANGE = 'FIELD_CHANGE';
	static FIELD_CHANGED = 'FIELD_CHANGED';
	static CUSTOM = 'CUSTOM';
	static CREATE = 'CREATE';
	static LOAD = 'LOAD';
	static LOADED = 'LOADED';
	static COLLECTION_INSERTED = 'COLLECTION_INSERTED';
	static COLLECTION_DELETED = 'COLLECTION_DELETED';
	static VALIDATE = 'VALIDATE';
	static FIELD_FILTERED = 'FIELD_FILTERED';
	static FIELD_TYPE_FILTERED = 'FIELD_TYPE_FILTERED';
	static FORM_LOAD = 'FORM_LOAD';
	static FORM_LOADED = 'FORM_LOADED';
	static GET_DATA_LEAVES = 'GET_DATA_LEAVES';
	static FORM_GET_NEXT = 'FORM_GET_NEXT';
	static CREATE_FROM = 'CREATE_FROM';
	static PERMISSION_CHECK = 'PERMISSION_CHECK';
	static VALIDATE_FIELD = 'VALIDATE_FIELD';
	static VALIDATE_FORM = 'VALIDATE_FORM';
	static BUILD = 'BUILD';
	static ROW_LOAD = 'ROW_LOAD';
	static ROW_LOADED = 'ROW_LOADED';
	static TREE_CHANGE = 'TREE_CHANGE';
	static HANDLE_EVENT = 'HANDLE_EVENT';
	static API = 'API';
	static AFTER_IMPORT = 'AFTER_IMPORT';
	static DELETE = 'DELETE';
	static ROW_CLICKED = 'ROW_CLICKED';
	static ROW_CLICK = 'ROW_CLICK';
	static TEST = 'TEST';
	static VALUE_SUGGEST = 'VALUE_SUGGEST';
	static VALUE_SUGGESTED = 'VALUE_SUGGESTED';
	static OPEN_CLICKED = 'OPEN_CLICKED';
	static CREATE_CLICKED = 'CREATE_CLICKED';
	static TREE_CHANGED = 'TREE_CHANGED';
	static ROUTE = 'ROUTE';
	static PROVIDE_GROUP_FOR_FILTER = 'PROVIDE_GROUP_FOR_FILTER';
	static APPLICATION_START = 'APPLICATION_START';

	static getBlockType (trigger) {
		let serverBlocks = [this.FORM_LOAD, this.VALIDATE_FORM, this.VALIDATE_FIELD,
			this.FIELD_CHANGE, this.ROW_CLICK, this.ROW_LOAD, this.VALUE_SUGGEST, this.LOAD, this.SAVE,
			this.VALIDATE, this.TREE_CHANGE, this.TREE_CHANGED, this.AFTER_IMPORT,
			this.DELETE, this.DOCUMENT_POST, this.GET_DATA_LEAVES, this.BUILD, this.ROUTE,
			this.PROVIDE_GROUP_FOR_FILTER, this.APPLICATION_START];
		if (serverBlocks.indexOf(trigger) >= 0) {
			return BlockType.SERVER;
		}
		return BlockType.CLIENT;
	};
	static getTriggersForBlock (field) {
		let ans = [];
		let type = field.type();

		if (type && type.isRegister()) {
			return ans;
		}
		if (type && type.isTransient()) {
			if (field.isCollection()) {
				ans.push(this.ROW_CLICKED);
			}
			return ans;
		}
		if (field.isCollection()) {
			ans = []
			if (!field.isVirtual()) {
				ans.push(this.COLLECTION_INSERTED)
				ans.push(this.COLLECTION_DELETED)
			}
			if (!type.isEmbedded()) {
				ans.push(this.ROW_CLICK);
				ans.push(this.ROW_CLICKED);
				ans.push(this.CREATE_CLICKED);
				ans.push(this.ROW_LOAD);
				ans.push(this.FIELD_FILTERED)
			} else if (type.isEmbedded()) {
				ans.push(this.ROW_CLICKED);
			}
		} else {
			if (type && type.isEmbedded()) {
				return ans;
			}
			ans = [this.FIELD_CHANGE, this.FIELD_CHANGED];
			if (field.isDynamic()) {
				ans.push(this.FIELD_TYPE_FILTERED);
			}
			if (field.isDynamic() || !type.isPrimitive()) {
				ans.push(this.FIELD_FILTERED)
			}
			if (type && !type.isPrimitive()) {
				ans.push(this.OPEN_CLICKED)
				ans.push(this.CREATE_CLICKED)
			}
			if (type && type.isPrimitive()) {
				ans.push(this.VALUE_SUGGEST);
				ans.push(this.VALUE_SUGGESTED);
			}
			ans.push(this.VALIDATE_FIELD);
		}
		return ans;
	}
}

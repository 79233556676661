import entityPresenter from '../../entity/views/entityPresenter';
import journalPresenter from '../../entity/views/journalPresenter';
import MultilingualString from '../models/multilingualString';
import {urls} from '../service/linkBuilder';
import {buildDefaultPopover} from './utils'
import ErrorCode from '../enums/errorCode';
import ConfirmModal from './confirmModal'
import { translate } from '../service/stringResourceService'

function getLoader() {
	return `
		<div class="loading" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0;">
			<div  class="preview-area" >
				<div class="spinner">
					<div class="dot1"></div>
					<div class="dot2"></div>
				</div>
			</div>
		</div>`
}

function updateTheme(tab) {
	let themeUrl = tab && tab.presentationContext && tab.presentationContext.themeUrl;
	let previousUrl = $('#cssTheme').attr('href')
	if (themeUrl !== undefined && themeUrl !== previousUrl){
		if (themeUrl) {
			$('#cssTheme').attr('href', themeUrl);
		} else {
			$('#cssTheme').removeAttr('href');
		}
	}
}

const getNextId = (() => {
	let id = 0;
	return () => ++id;
})();


class CjTab {
		constructor(options) {
			this.id = getNextId();
			this.tabs = options.tabs;
			this.parent = options.parent;
			this.history = [];
			this.init(options);
		}

		init(options) {
			if (options.onRender) {
				this.onRender = options.onRender;
			}
			this.callerModel = options.exactParent;
			this.url = options.url;
			this.title = options.title;
			this.$el = options.$el;
			this.presentationInfo = options.presentationInfo;
			this.showLoader = options.showLoader;
			if (this.presentationInfo) {
				this.presentationInfo.onClose = this.presentationInfo.onClose
					|| ((afterDelete) => {
						this.tabs.checkBeforeClose(this)
						afterDelete && this.presentationInfo.afterSaved && this.presentationInfo.afterSaved()
					});
				const currentAfterFormLoaded = this.presentationInfo.afterFormLoaded;
				this.presentationInfo.afterFormLoaded = () => {
					app.useCss && this._initToolbar();
					buildDefaultPopover($('.popover-tooltip'),(window.matchMedia("(max-width: 767px)").matches)?{placement:  'bottom'}: {})
					return currentAfterFormLoaded ? currentAfterFormLoaded() : Promise.resolve();
				};
			}
		}

		_initToolbar() {
			const toolbar = this.$el.find('.fixed-toolbar').first();
			const tabsPanel = this.tabs.selector.$panel
			this.$el.prepend(toolbar);
			if (app.withoutHeader) {
				// When open a few forms without header one after another in some cases we get overlay tabs panel over form toolbar.
				// It happens when browser define for one fixed element class affix and for another affix-top.
				// It seems like a consequence of initializing affix with value 0, so to prevent this we init affix with value 1px.
				this.addAffix(1, toolbar, tabsPanel)
			} else {
				this.addAffix(50, toolbar, tabsPanel)
			}
		}

		addAffix(val, toolbar, tabsPanel) {
			toolbar.affix({offset:{top: val}});
			tabsPanel.affix({offset:{top: val}})
		}

		async render() {
			if (!this.$el) {
				this.$el = $('<div class="cj-tab">');
				if (this.showLoader) {
					this.$el.append(getLoader());
					this.$el.addClass('hold-transition');
				}
				if (!this.onRender) {
					this.$el.append(`
						<div class="content">
							<div class="form">
								<div class="forms-container">
							</div>
						</div>`);
				}
				this.tabs.$el.append(this.$el);
			}
			if (this.onRender) {
				this.onRender(this);
			}
			if (this.presentationInfo) {
				this.presentationInfo.afterCreated = this.presentationInfo.afterCreated
					|| ((ctx) => {
						let newUrl = app.urls.update(ctx.type.id, ctx.objectId)
						window.history.replaceState(null, document.title, newUrl)
						ctx.applicationState.resetUrl()
						this.url = newUrl
					})
				if (this.showLoader) {
					this.presentationInfo.hideLoading = () => this._hideLoading();
				}
				try {
					this.presentationContext = await entityPresenter.present(
						_.extend(this.presentationInfo, {
						el: this.$el,
						toolbar: this.$el.find('.fixed-toolbar'),
						ignoreBlocks: app.builderMode,
						viewControl: this
					}));
					if (this.presentationContext.flowId && !app.cjHelper.isShown(this.presentationContext.flowId)) {
						app.cjHelper.triggerFlow(this.presentationContext.flowId)
					}
					this.title = this.title || this.presentationContext.title;
					if (app.useCss) {
					if (this.presentationContext.showToolbar) {
						this.tabs.selector.$panel.find('.white-panel').hide()
						this.tabs.selector.$panel.next().hide()
					} else {
						this.tabs.selector.$panel.find('.white-panel').show()
						this.tabs.selector.$panel.next().show()
					}
				}
				} catch (e) {
					if (e.message == ErrorCode.META_DATA_IS_STALE) {
						app.notificationManager.addError(
							MultilingualString.fromStringInCurrentLanguage(translate('stale.meta.data'))
						);
						this.close();
					} else {
						throw e;
					}

				}
			}
		}

		_hideLoading() {
			this.$el.find('.loading').fadeOut('fast')
			setTimeout(() => {
				this.$el.removeClass('hold-transition');
				this.$el.find('.loading').remove();
			}, 100);
		}

		stopPresentation() {
			if (this.presentationContext) {
				this.presentationContext.destroy();
			}
		}

		destroy() {
			this.stopPresentation();
			this.$el.remove();
		}

		close() {
			this.tabs.closeTab(this);
		}

		isPresenation() {
			return this.presentationContext != null;
		}
}


class CjTabSelector {
	constructor(cjTabs) {
		this.tabs = cjTabs;
		this.$el = $(`<div class="tab-selector"></div>`).prependTo(cjTabs.$el);
		this.$el.wrap(`<div class="tabs-panel affix-top" data-spy="affix" data-offset-top="50" style="display: none;"></div>`)
		this.$panel = this.$el.parent()
		this.$panel.append('<div class="white-panel" style="width: 100%; height: 14px; background-color: white; display: none;"></div>')
		this.$panel.after(`<div class="fake-toolbar"></div>`)
		this.$el.parent().prepend(`<button type="button" class="btn btn-md tabs-scroll tabs-scroll-left" style="left: 0; border-left: none; display: none;">
																<span class="fa fa-chevron-left mirror-in-rtl"></span>
															</button>
															<button type="button" class="btn btn-md tabs-scroll tabs-scroll-right" style="right: 0; border-right: none; display: none;">
																<span class="fa fa-chevron-right mirror-in-rtl"></span>
															</button>
															<div class="tabs-shadow left-shadow" style="display: none;"></div>
															<div class="tabs-shadow right-shadow" style="display: none;"></div>`)
		this.$el.on('click', '.close-tab', (e) => {
			this.$el.find('.tab-link').popover('hide');
			this.tabs.tryCloseTabById($(e.currentTarget).attr('data-tab-id'));
		});
		this.tabWidth = () => { return $(window).width() > 768 ? 155 : 75 }
		this.$panel.find('.tabs-scroll-right').click(() => { this.rightScroll() })
		this.$panel.find('.tabs-scroll-right').on('touchstart', () => { this.rightScroll() })
		this.$panel.find('.tabs-scroll-left').click(() => { this.leftScroll() })
		this.$panel.find('.tabs-scroll-left').on('touchstart', () => { this.leftScroll() })
		$(window).on('resize', () => {
			this.checkTabs()
		})
		$('.sidebar-toggle').click(() => {
			setTimeout(() => { this.checkTabs() }, 300)
		})
		$('#tutorials-button').click(() => {
			setTimeout(() => { this.checkTabs() }, 300)
		})
	}

	rightScroll() {
		this.$el.scrollLeft(this.$el.scrollLeft() + this.tabWidth())
		this.checkShadows()
	}

	leftScroll() {
		this.$el.scrollLeft(this.$el.scrollLeft() - this.tabWidth())
		this.checkShadows()
	}

	addTab(tab) {
		let $closeButton = $('<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>')
		let titleForTab = !tab.title ? translate('entries.loading') : tab.title
		let $tabContent = $(`<div class="tab-content" title='${titleForTab}'/>`).append(`<span class="tab-title">${titleForTab}</span>`).append($closeButton)
		const el = $(`<div class="tab-link"></div>`).append($tabContent).appendTo(this.$el)
		$tabContent.append('<div class="cross-back-hover"></div>')
		el.attr('data-tab-id', tab.id);
		el.click(() => {
			this.tabs.activateTab(tab);
		});
		$closeButton.click(() => {
			if (tab.presentationContext && tab.presentationContext.onClose) {
				tab.presentationContext.onClose()
			} else {
				this.tabs.closeTab(tab)
			}
		})
		buildDefaultPopover(el, {
			trigger: 'manual',
			placement: 'bottom',
			html: true,
			content: () => this.getPopover(tab),
			animation: false,
		})
		this.checkTabs()
		if (app.withoutHeader) {
			// See comment in _initToolbar function.
			this.$panel.attr('data-offset-top', 1)
		}
		this.$el.scrollLeft(this.$el.scrollLeft() + this.tabWidth())
	}

	getPopover(tab) {
		let html = `<span>${tab.title}</span>
				<button class="btn btn-link close-tab" data-tab-id="${tab.id}">
					<i class="fa fa-times"></i>
				</button>`;
		if ($(`[data-tab-id=${tab.id}]`).find('.tab-content').css('display') != 'none') {
			return null
		}
		return html;
	}

	activate(tab) {
		updateTheme(tab);
		const el = this.$el.find(`[data-tab-id="${tab.id}"]`);
		this.$el.find('.active').removeClass('active');
		el.addClass('active');
	}

	remove(tab) {
		this.$el.find(`[data-tab-id="${tab.id}"]`).remove();
		this.checkTabs()
	}

	checkTabs() {
		if (this.tabs.tabs.length <= 1) {
			this.$panel.hide()
			this.$el.closest('.cj-tabs').removeClass('with-tabs-panel')
		} else {
			this.$panel.show()
			this.$el.closest('.cj-tabs').addClass('with-tabs-panel')
		}
		if ( this.tabs.tabs.length * this.tabWidth() < this.$el.width() ) {
			this.$panel.find('.tabs-scroll').hide()
		} else {
			this.$panel.find('.tabs-scroll').show()
		}
		this.checkShadows()

	}

	checkShadows() {
		let tabsFullWidth = this.tabs.tabs.length * this.tabWidth()
		if ( tabsFullWidth <= this.$el.width() ) {
			this.$panel.find('.right-shadow').hide()
			this.$panel.find('.left-shadow').hide()
		} else {
			if ( this.$el.width() + this.$el.scrollLeft() < tabsFullWidth ) {
				this.$panel.find('.right-shadow').show()
			} else {
				this.$panel.find('.right-shadow').hide()
			}
			if (this.$el.scrollLeft() == 0) {
				this.$panel.find('.left-shadow').hide()
			} else {
				this.$panel.find('.left-shadow').show()
			}
		}
	}

}

class CjTabs {
	constructor() {
		this.$el = $('.cj-tabs');
		this.tabs = [];
		this.activeTab = null;
		if (app.useCss) {
			this.selector = new CjTabSelector(this);
			$(window).on('popstate', () => {
				const prev = this.activeTab && this.activeTab.history.pop();
				if (prev) {
					this._replaceTabContent(this.activeTab, prev);
				} else {
					if (this.tabs.length > 1) {
						this.checkBeforeClose(this.activeTab);
					}
				}
			});
		}

	}

	createPresentationTab(url, callback, opts) {
		opts = opts || {};
		this.createTab({
			showLoader: false,
			url: url,
			title: opts.title,
			presentationInfo: _.extend(opts, {
				url: url,
				afterSaved: callback
			})
		})
	}

	createTab(options) {
		options.tabs = this;
		options.parent = this.activeTab;
		const tab = new CjTab(options);
		this.tabs.push(tab);
		tab.render().then(() => this._updateTitle());
		app.useCss && this.selector.addTab(tab);
		this.activateTab(tab);
	}

	_updateTitle() {
		if(this.activeTab.title) {
			updateCjTitle(this.activeTab.title, this.activeTab.id)
		}
	}
	_pushMessageUpdateUrl(id, typeId, viewId, url, treeId) {
		if (!id && !typeId && url) {
			let paramsPart = url.includes('?') ? url.slice(url.indexOf('?')) : null
			let urlStr = url.slice(url.indexOf('entity'), paramsPart ? url.indexOf('?') : url.length)
			typeId = urlStr.slice(6, 25)
			if (urlStr.endsWith('/')) {
				urlStr = urlStr.slice(0, -1)
			}
			if (urlStr.includes('create')) {
				id = null
			} else {
				id = urlStr.lastIndexOf('/') != -1 && urlStr.slice(urlStr.lastIndexOf('/') + 1) || null
			}
			if (paramsPart) {
				let viewName
				if (paramsPart.includes('viewId')) viewName = 'viewId'
				else if (paramsPart.includes('rowViewId')) viewName = 'rowViewId'
				else if (paramsPart.includes('formViewId')) viewName = 'formViewId'
				else if (paramsPart.includes('listViewId')) viewName = 'listViewId'
				if (viewName) {
					let value = paramsPart.slice(paramsPart.indexOf(viewName) + viewName.length + 1)
					if (value.includes('/')) value = value.slice(0, value.indexOf('/'))
					if (value.includes('&')) value = value.slice(0, value.indexOf('&'))
					viewId = value
				}
			}
		}
		parent && parent.postMessage && parent.postMessage({id: id, typeId:typeId, viewId: viewId, url: url, treeId: treeId, isUpdateLink: true},"*")
	}
	activateTab(tab, prevInfo) {
		if (this.activeTab) {
			this.activeTab.savedScroll = window.scrollY;
		}
		this.activeTab = tab;
		window.history.pushState(prevInfo, tab.title, tab.url);
		this._updateTitle();
		const context = tab.presentationInfo
		context && this._pushMessageUpdateUrl(context.objectId, context.typeId, context.viewId, context.url, context.treeId)
		this.$el.find('.cj-tab').hide();
		tab.$el.show();
		tab.presentationContext && tab.presentationContext.update();
		app.useCss && this.selector.activate(tab);
		if (tab.savedScroll) {
			window.scroll(0, tab.savedScroll);
			tab.savedScroll = null;
		}
	}

	showInCurrentTab(url, opts) {
		if (this.activeTab.isPresenation()) {
			this.activeTab.history.push(this.activeTab.presentationInfo.url);
		}
		this._replaceTabContent(this.activeTab, url, opts);
		this.activateTab(this.activeTab);
	}

	_replaceTabContent(tab, url, opts) {
		opts = opts || {};
		tab.stopPresentation();
		tab.init({
			url: url,
			presentationInfo: _.extend(opts, {
				url: url,
			})
		});
		tab.render().then(() => this._updateTitle());
	}

	canShow() {
		return !$('body').hasClass('editing-instance');
	}

	closeTab(tab) {
		let tabToActivate;
		if (tab == this.activeTab) {
			tabToActivate = this.activeTab.parent;
			if (tabToActivate && this.tabs.length == 1) {
				app.utils.redirectTo(tabToActivate.url)
				return
			}
			if (!tabToActivate || this.tabs.indexOf(tabToActivate) === -1) {
				tabToActivate = this.tabs[this.tabs.length-1];
				if (tabToActivate === tab) {
					tabToActivate = this.tabs[this.tabs.length-2];
				}
			}
		}
		if (!tabToActivate && this.tabs.length == 1) {
			app.utils.redirectTo(app.urls.indexPage(tab.presentationContext.typeId))
		}
		this.tabs.splice(this.tabs.indexOf(tab), 1);
		this.selector.remove(tab);
		tab.destroy();
		if (tabToActivate) {
			updateTheme(tabToActivate);
			this.activateTab(tabToActivate);
		}
	}

	checkBeforeClose(tab) {
		if (tab.preventPageLeave && tab.preventPageLeave.predicate && tab.preventPageLeave.predicate()) {
			var modal = new ConfirmModal()
			modal.show({
				headerResource: 'unsaved.changes',
				resource: 'unstaged.changes',
				buttons: {
					'save': (e) => {
						tab.preventPageLeave.save(e)
					},
					'continue': () => {}
				},
				then: () => {
					tab.preventPageLeave.removeEventHandler && tab.preventPageLeave.removeEventHandler()
					this.closeTab(tab)
			}})
		}
		else {
			this.closeTab(tab)
		}
	}

	tryCloseTabById(id) {
		const tab = _.find(this.tabs, tab => tab.id === +id);
		this.checkBeforeClose(tab);
	}

	tryCloseCurrentTab() {
		this.checkBeforeClose(this.activeTab);
	}

};

export function updateCjTitle (title, id) {
	document.title = title
	let $tab = $(`[data-tab-id=${id}]`)
	$tab.find('.tab-title').text(title)
	$tab.find('.tab-content').attr('title', title)
}


export default new CjTabs();
